import { Tag } from '@hawkins/components';
import React from 'react';
import { colors } from '@hawkins/variables';

export const MissingThumbnail = () => {
  return (
    <Tag variant={'outlined'} color={colors.grayT20}>
      Missing Thumbnail
    </Tag>
  );
};
