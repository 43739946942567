import React from 'react';
import { createRoot } from 'react-dom/client';

import { App } from './App';
import { initFullstory } from './hooks/useFullstory';
const container = document.getElementById('root');
if (!container) {
  throw new Error('Could not find root container');
}
const root = createRoot(container);
const initFS = initFullstory();
initFS();
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
