import { createContext } from 'react';

import { heightThreshold, widthThreshold } from './constants';

export interface ScreenInterface {
  isTouch: boolean;
  isWidthSmall: boolean;
  isHeightSmall: boolean;
  width: number;
  height: number;
}
export const defaultValue: ScreenInterface = {
  isTouch: window?.matchMedia('(hover: none)')?.matches || false,
  width: window.innerWidth,
  height: window.innerHeight,
  isWidthSmall: window.innerWidth < widthThreshold,
  isHeightSmall: window.innerHeight < heightThreshold,
};
export const ScreenContext = createContext(defaultValue);
