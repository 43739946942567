import { Feedback } from '@hawkins/components';
import React, { useCallback, useState } from 'react';

export const WarningBanner = () => {
  const [isOpen, setIsOpen] = useState(true);
  const onClose = useCallback(() => setIsOpen(false), []);
  return (
    <Feedback
      open={isOpen}
      onClose={onClose}
      position={'bottom-left'}
      autoHideDuration={6000}
      message='This site and its contents are intended for authorized users only.'
      variant='warning'
    />
  );
};
