import { useQuery } from '@tanstack/react-query';
import { useCallback, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import { PublicBatch } from '../../components/batch/types';

class FetchError extends Error {
  constructor(public res: Response, message?: string) {
    super(message);
  }
}
const getShareById = (props: { shareId: string; password?: string | null }): Promise<PublicBatch> => {
  const { shareId, password } = props;
  return fetch(`/share/${shareId}${password ? `?password=${encodeURIComponent(password)}` : ''}`).then(
    async (response) => {
      if (response.status !== 200) {
        throw new FetchError(response);
      }
      const data = await response.json();

      return data as PublicBatch;
    },
  );
};

export interface ShareHook {
  accessDenied: boolean;
  batch: PublicBatch | undefined;
  isLoading: boolean;
  isError: boolean;
}
export const useShare = (password?: string | null): ShareHook => {
  const { shareKey } = useParams();
  if (!shareKey) {
    throw new Error('cannot load share without a key');
  }
  const [accessDenied, setAccessDenied] = useState(false);
  const fetchShare = useCallback(
    (): Promise<PublicBatch> => getShareById({ shareId: shareKey, password }),
    [password, shareKey],
  );
  const onError = useCallback((err: unknown) => {
    if ((err as FetchError)?.res?.status === 401) {
      setAccessDenied(true);
    }
  }, []);
  const onSuccess = useCallback(() => {
    setAccessDenied(false);
  }, []);
  const fetchOptions = useMemo(
    () => ({
      enabled: !!shareKey,
      refetchOnWindowFocus: false,
      refetchOnReconnect: true,
      retry: false,
      onError,
      onSuccess,
    }),
    [onError, onSuccess, shareKey],
  );
  const {
    data: batch,
    isLoading,
    isError,
  } = useQuery<PublicBatch>(['share', shareKey, password], fetchShare, fetchOptions);

  return {
    accessDenied,
    batch,
    isLoading,
    isError,
  };
};
