import { AppLogo, ErrorState } from '@hawkins/components';
import { spacings } from '@hawkins/variables';
import React from 'react';

import Astronaut from '../../astronaut.png';
import { PublicBatch } from '../batch/types';
import { LoginButton } from '../LoginButton';
import { formattedAppName } from '../../util';

interface ExpiredProps {
  batch: PublicBatch;
}

export const ShowExpired = ({ batch }: ExpiredProps): JSX.Element => {
  const appName: string = formattedAppName(batch.app);
  return (
    <ErrorState
      layout={{ padding: spacings.space5 }}
      appLogo={<AppLogo name='Asset Share' studioLogo />}
      illustration={<img src={Astronaut} alt='' />}
      subtitle={`You can reactivate the link by logging in to ${appName}.`}
      title={`The ${appName} link you're trying to access has expired.`}
      actions={<LoginButton batch={batch} />}
    />
  );
};
