import { makeStyles } from '@hawkins/styles';
import { Text, TextProps, Tooltip } from '@hawkins/components';
import React from 'react';

const useStyles = makeStyles({
  root: {
    wordWrap: 'break-word',
    wordBreak: 'break-all',
  },
});

export const FileName = ({ children, ...rest }: TextProps) => {
  const classNames = useStyles();
  return (
    <Tooltip label={children}>
      <Text level={1} classes={{ root: classNames.root }} {...rest}>
        {children}
      </Text>
    </Tooltip>
  );
};
