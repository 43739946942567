import React from 'react';
import { Tag } from '@hawkins/components';
import { colors } from '@hawkins/variables';

export const WatermarkInProgress = () => {
  return (
    <Tag variant={'outlined'} color={colors.grayT20}>
      Watermark In Progress
    </Tag>
  );
};
