import React from 'react';
import { Box } from '@hawkins/components';
import { IconRefreshExclamationPoint } from '@hawkins/icons';
import { colors } from '@hawkins/variables';

export const ThumbnailError = ({
  height = 40,
  children,
}: {
  height?: number;
  children?: React.ReactNode;
}): JSX.Element => {
  return (
    <Box
      display={'flex'}
      justifyContent={'center'}
      alignItems={'center'}
      height={'100%'}
      border={`1px solid ${colors.grayT40}`}
    >
      {children ? children : <IconRefreshExclamationPoint height={height} />}
    </Box>
  );
};
