import { useCallback } from 'react';

interface DownloadURLProps {
  shareKey: string;
  assetId?: string;
  childAssetId: string;
  password?: string | null;
}

class DownloadURLError extends Error {
  constructor(message: string) {
    super(message);
    this.name = 'DownloadURL';
  }
}

export const useDownloadURL = () => {
  return useCallback(({ shareKey, assetId, childAssetId, password }: DownloadURLProps): string => {
    if (!assetId) {
      throw new DownloadURLError('Asset ID is required');
    }

    return `/share/${shareKey}/${assetId}/${childAssetId}/download/${
      password ? `?password=${encodeURIComponent(password)}` : ''
    }`;
  }, []);
};
