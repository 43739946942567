import React, { useContext } from 'react';
import { AppLogo, Box, Heading, HorizontalNavigation, Text, Tooltip } from '@hawkins/components';
import { colors, spacings } from '@hawkins/variables';
import { DateTime } from 'luxon';

import { useScreenContext } from '../../hooks';
import { BatchContext } from '../../context';
import { LoginButton } from '../LoginButton';
import { DownloadAllAssets } from '../DownloadAll';
import { logError } from '../../util';

export const Header = (): JSX.Element => {
  const { isHeightSmall } = useScreenContext();
  const batch = useContext(BatchContext);
  if (!batch || !batch.share) {
    logError(new Error('cannot be rendered without data'));
    throw new Error('cannot be rendered without data');
  }
  return (
    <>
      <HorizontalNavigation
        transparent
        logo={<AppLogo name='Asset Share' studioLogo envLabel={isTest ? 'test' : undefined} />}
        style={{ backgroundColor: colors.blackT40 }}
        userControls={isHeightSmall ? <LoginButton batch={batch} compact /> : undefined}
      >
        {isHeightSmall ? <Heading level={2}>{batch.name}</Heading> : undefined}
      </HorizontalNavigation>
      <Box
        style={{ backgroundColor: colors.blackT40 }}
        padding={`0 ${spacings.space5} ${isHeightSmall ? spacings.space2 : spacings.space5} ${spacings.space5}`}
        gap={spacings.space2}
        display='flex'
        flexDirection='column'
      >
        {isHeightSmall ? (
          <RenderShareInfo />
        ) : (
          <>
            <Heading level={isHeightSmall ? 0 : 5}>{batch.name}</Heading>
            <RenderShareInfo />
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <LoginButton batch={batch} />
              <DownloadAllAssets />
            </div>
          </>
        )}
      </Box>
    </>
  );
};

const RenderShareInfo = () => {
  const batch = useContext(BatchContext);
  if (!batch || !batch.share) {
    throw new Error('cannot be rendered without data');
  }
  return (
    <Box gap={spacings.space0} display='flex' flexDirection='row' alignItems='center'>
      <Text variant='label' level={1} colorVariant='faded'>
        {batch.assetIds.length} assets &middot;
      </Text>
      <Tooltip label={DateTime.fromMillis(batch.share.createdAt).toLocaleString(DateTime.DATETIME_FULL)}>
        <Text variant='label' level={1} colorVariant='faded'>
          Created {DateTime.fromMillis(batch.share.createdAt).toRelative()} &middot;
        </Text>
      </Tooltip>
      <Tooltip label={DateTime.fromMillis(batch.share.endDate).toLocaleString(DateTime.DATETIME_FULL)}>
        <Text variant='label' level={1} colorVariant='faded'>
          Expires {DateTime.fromMillis(batch.share.endDate).toRelative()}
        </Text>
      </Tooltip>
    </Box>
  );
};

const isTest = window.location.hostname.includes('test') || window.location.hostname.includes('local');
