import { useQuery } from '@tanstack/react-query';
import { useCallback, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

class FetchError extends Error {
  constructor(public res: Response, message?: string) {
    super(message);
  }
}
const getShareById = (props: { shareId: string }): Promise<string> => {
  const { shareId } = props;
  return fetch(`/share/vandam/${shareId}`).then(async (response) => {
    if (response.status !== 200) {
      throw new FetchError(response);
    }
    const data = await response.json();

    return JSON.stringify(data);
  });
};

export const useVandamShare = () => {
  const { shareKey } = useParams();
  if (!shareKey) {
    throw new Error('cannot load share without a key');
  }
  const [accessDenied, setAccessDenied] = useState(false);
  const fetchShare = useCallback((): Promise<string> => getShareById({ shareId: shareKey }), [shareKey]);
  const onError = useCallback((err: unknown) => {
    if ((err as FetchError)?.res?.status === 401) {
      setAccessDenied(true);
    }
  }, []);
  const onSuccess = useCallback(() => {
    setAccessDenied(false);
  }, []);
  const fetchOptions = useMemo(
    () => ({
      enabled: !!shareKey,
      refetchOnWindowFocus: false,
      refetchOnReconnect: true,
      retry: false,
      onError,
      onSuccess,
    }),
    [onError, onSuccess, shareKey],
  );
  const { data: batch, isLoading, isError } = useQuery<string>(['share', shareKey], fetchShare, fetchOptions);

  return {
    accessDenied,
    batch,
    isLoading,
    isError,
  };
};
