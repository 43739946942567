import React from 'react';
import { Routes, Route, BrowserRouter } from 'react-router-dom';
import { HawkinsProvider, CSSBaseline } from '@hawkins/components';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import { Main, Vandam } from './components';
import { ScreenContext, useScreenContextValue } from './hooks';
import { ShowError } from './components/ShowError';
import { WarningBanner } from './components/WarningBanner';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

export const App = (): JSX.Element => {
  const screenContext = useScreenContextValue();
  return (
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <HawkinsProvider theme='dark'>
          <ScreenContext.Provider value={screenContext}>
            <CSSBaseline />
            <WarningBanner />
            <Routes>
              <Route element={<Vandam />} path='/vandam/:shareKey' />
              <Route element={<Main />} path='/:shareKey' />
              <Route element={<Main />} path='/:shareKey/:focusKey' />
              <Route element={<Main />} path='/:shareKey/:focusKey/:action' />
              <Route element={<ShowError />} path='/' />
            </Routes>
          </ScreenContext.Provider>
        </HawkinsProvider>
      </BrowserRouter>
    </QueryClientProvider>
  );
};
