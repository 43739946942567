import { useQuery, UseQueryResult } from '@tanstack/react-query';

export interface GetDetailsForAssetResponse {
  assetId: string;
  assetType: string;
  name: string;
  downloadableAssets: PublicAssetFile[];
  isVideoPlayable: boolean;
  watermarkStatus?: 'COMPLETED' | 'IN_PROGRESS' | null;
  mimeType?: string;
  sizeInBytes?: number;
}
export interface PublicAssetFile {
  assetId: { id: string };
  fileContentType: string;
  fileName: string;
  sizeInBytes?: number;
}

export interface DetailsCache {
  fetchDetails: (assetId: string) => Promise<GetDetailsForAssetResponse>;
}

export const getAssetDetailById = (shareId: string, assetId: string, password: string | null) =>
  fetch(`/share/${shareId}/${assetId}/detail${password ? `?password=${encodeURIComponent(password)}` : ''}`).then(
    (response) =>
      response.json().then((data) => {
        return data as GetDetailsForAssetResponse;
      }),
  );

export const useAssetDetail = (
  shareId: string | null,
  assetId: string | null,
  password: string | null,
): UseQueryResult<GetDetailsForAssetResponse, unknown> => {
  return useQuery(['assetDetail', assetId, password], () => getAssetDetailById(shareId!, assetId!, password), {
    enabled: !!assetId && !!shareId,
    refetchOnMount: false,
  });
};
