import { useMemo, useState, useEffect, useContext } from 'react';

import { heightThreshold, widthThreshold } from './constants';
import { ScreenContext, ScreenInterface } from './ScreenContext';

export const useScreenContextValue = (): ScreenInterface => {
  const isTouch = useMemo(() => matchMedia('(hover: none)')?.matches || false, []);
  const { width, height } = useWindowSize();
  const isWidthSmall = useMemo(() => width < widthThreshold, [width]);
  const isHeightSmall = useMemo(() => height < heightThreshold, [height]);
  return {
    isTouch,
    width,
    height,
    isWidthSmall,
    isHeightSmall,
  };
};

// Hook
const useWindowSize = (): { width: number; height: number } => {
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  return windowSize;
};
export const useScreenContext = (): ScreenInterface => {
  const val = useContext(ScreenContext);
  if (!val) {
    throw new Error('no screen context initialized');
  }
  return val;
};
