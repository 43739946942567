import { Badge, Text, Tooltip } from '@hawkins/components';
import { makeStyles } from '@hawkins/styles';
import { colors } from '@hawkins/variables';
import React, { useMemo } from 'react';

const useCounterChipStyles = makeStyles({
  indexChip: {
    gridColumnStart: 2,
    gridColumnEnd: 5,
    gridRowStart: 2,
    gridRowEnd: 5,
    alignSelf: 'start',
    justifySelf: 'start',
  },
  indexTop: {
    gridColumnStart: 1,
    gridColumnEnd: 5,
    gridRowStart: 1,
    gridRowEnd: 5,
    alignSelf: 'start',
    justifySelf: 'start',
  },
});
interface CounterChipProps {
  index: number;
  total: number;
}

export const CounterChip = ({ index, total }: CounterChipProps) => {
  return null;
  //return <Option2 index={index} total={total} />;
};

export const Option1 = ({ index, total }: CounterChipProps) => {
  const classes = useCounterChipStyles();
  return (
    <Tooltip label={`${index + 1} of ${total}`}>
      <Badge className={classes.indexChip} color='UNKNOWN'>{`${index + 1}`}</Badge>
    </Tooltip>
  );
};

export const Option2 = ({ index, total }: CounterChipProps) => {
  const classes = useCounterChipStyles();
  return (
    <>
      <div className={classes.indexTop}>
        <Tooltip label={`${index + 1} of ${total}`}>
          <svg width='50' height='50' xmlns='http://www.w3.org/2000/svg'>
            <g>
              <polygon id='mypol' points='0 0 35 0 0 35' fill={colors.gray800}></polygon>
            </g>
          </svg>
        </Tooltip>
      </div>
      <Text style={{ color: colors.whiteT90 }} className={classes.indexTop} variant='label' bold level={1}>{`${
        index + 1
      }`}</Text>
    </>
  );
};

export const Option3 = ({ index, total }: CounterChipProps) => {
  const classes = useCounterChipStyles();
  return (
    <>
      <div className={classes.indexTop}>
        <Tooltip label={`${index + 1} of ${total}`}>
          <svg width='50' height='50' xmlns='http://www.w3.org/2000/svg'>
            <g>
              <polygon id='mypol' points='15 0 45 0 0 45 0 15' fill={colors.gray800}></polygon>
            </g>
          </svg>
        </Tooltip>
      </div>
      <Text
        style={{
          display: 'block',
          transform: 'rotate(-45deg)',
          transformOrigin: 'center center',
        }}
        className={classes.indexChip}
        variant='label'
        bold
        level={1}
      >{`${index + 1}`}</Text>
    </>
  );
};

export const Option4 = ({ index, total }: CounterChipProps) => {
  const classes = useCounterChipStyles();
  const svgPath = useMemo(() => {
    const textWidth = (index + 1).toString().length * 8;
    const begin = 4;
    const pad = 3;
    return `${begin} 0 ${begin} 35 ${begin + pad + textWidth / 2} 25 ${begin + pad + textWidth + pad} 35 ${
      begin + pad + textWidth + pad
    } 0`;
  }, [index]);
  return (
    <>
      <div className={classes.indexTop}>
        <Tooltip label={`${index + 1} of ${total}`}>
          <svg width='50' height='50' xmlns='http://www.w3.org/2000/svg'>
            <g>
              <polygon id='mypol' points={svgPath} fill={colors.gray800}></polygon>
            </g>
          </svg>
        </Tooltip>
      </div>
      <Text
        style={{
          display: 'block',
        }}
        className={classes.indexChip}
        variant='label'
        bold
        level={1}
      >{`${index + 1}`}</Text>
    </>
  );
};
