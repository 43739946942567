const fileExtensionsWithoutThumbnail = new Set([
  'aaf',
  'aegraphic',
  'aep',
  'ai',
  'aif',
  'app',
  'arw',
  'arx',
  'avb',
  'avif',
  'bim',
  'bin',
  'braw',
  'cap',
  'cop',
  'cos',
  'cosessiondb',
  'cpf',
  'cr',
  'cr2',
  'cr3',
  'csv',
  'cube',
  'db',
  'dfxp',
  'dng',
  'doc',
  'docx',
  'drp',
  'drx',
  'edl',
  'eip',
  'eps',
  'fbx',
  'fdx',
  'gif',
  'gz',
  'heic',
  'idml',
  'iiq',
  'indd',
  'ini',
  'jpf',
  'key',
  'lst',
  'm4a',
  'mhl',
  'mif',
  'mogrt',
  'omf',
  'otf',
  'pek',
  'prproj',
  'r',
  'raf',
  'srt',
  'ttf',
  'txt',
  'vrw',
  'xlsx',
  'xml',
  'xmp',
  'zip',
]);

const fileExtensionsWithoutPreview = new Set([
  'ai',
  'aep',
  'dfxp',
  'mp3',
  'mxf',
  'partial',
  'pdf',
  'psb',
  'psd',
  'tif',
  'tiff',
  'txt',
  'wav',
  'zip',
  'ztl',
]);

export const hasNoGeneratedFileForThumbnail = (filename: string) => {
  const fileExt = filename.split('.').pop();
  return !fileExt || fileExtensionsWithoutThumbnail.has(fileExt.toLowerCase());
};

export const hasNoGeneratedFileForPreview = (filename: string) => {
  const fileExt = filename.split('.').pop();
  return !fileExt || fileExtensionsWithoutPreview.has(fileExt.toLowerCase());
};
