import { FullStory, init, isInitialized } from '@fullstory/browser';

const isTest = window.location.hostname.includes('test') || window.location.hostname.includes('local');

export const initFullstory = () => {
  const initFS = () => {
    if (!isInitialized()) {
      init({ debug: isTest, devMode: isTest, orgId: 'o-1Y97AF-na1' }, ({ sessionUrl }) => {
        if (!sessionUrl) {
          // remove next line after some time
          console.log('No session created');
        }
        FullStory('setIdentity', { anonymous: true });
      });
    }
  };
  return initFS;
};
